@tailwind base;
@tailwind components;
@tailwind utilities;

.drop-down-wrapper {
  box-shadow: 0 0 12px rgba(0,0,0,0.16);
  z-index : 3;
  @apply flex flex-col w-44 justify-center items-center absolute bg-white rounded-xl p-2
}

.close-btn-wrapper {
  @apply w-full flex flex-row justify-end items-end p-0
}

.background {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  background-color: #1c1515;
  opacity: 0.8;
}
