.circle_progress_wrap {
  position: relative;
  width: 120px;
  height: 120px;
}
.circle_progress { transform: rotate(-90deg); }
.frame, .bar { fill: none; }
.frame { stroke: #e6e6e6; }
.bar {
  stroke: #03c75a;
  stroke-linecap: round;
}
.value {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  color: #888;
  font-size: 16px;
  line-height: 120px;
}
