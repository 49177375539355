.notes {
  background-attachment: local;
  background-image:
          linear-gradient(to right, white 10px, transparent 10px),
          linear-gradient(to left, white 10px, transparent 10px),
          repeating-linear-gradient(white, white 35px, #ccc 35px, #ccc 36px, white 36px);
  line-height: 36px;
  font-size: 16px;
  font-weight: 400;
  resize: none;
  height: 20.25rem;
  ::placeholder {
    line-height: 36px;
    font-size: 16px;
    font-weight: 400;
  }
  padding: 8px 10px;
}
