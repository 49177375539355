@tailwind base;
@tailwind components;
@tailwind utilities;

.empty-header {
  @apply w-full h-[60px] bg-zp-primary
}

.bottom-group-form {

}
