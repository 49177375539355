@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #FF90A0;
  box-shadow:
    inset 0px 1px 5px
    rgba(255, 255, 255, 0.1),
    inset 0px -1px 5px
    rgba(28, 21, 21, 0.05);
  cursor: pointer;
}

.difficulty-range-label {
  @apply leading-4 font-normal
}
